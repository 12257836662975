<script>
export default {
  name: "IconLock",
}
</script>

<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
  <g transform="translate(4.5, 4)">
    <path d="M6.66667 9.41175C6.66667 8.61572 7.42667 7.99163 8.29333 8.16994C8.77333 8.26546 9.18 8.63482 9.29333 9.09334C9.44667 9.69196 9.15333 10.2396 8.66667 10.5071V11.7553C8.66667 11.8699 8.57333 11.9591 8.45333 11.9591H7.54667C7.42667 11.9591 7.33333 11.8699 7.33333 11.7553V10.5071C6.93333 10.2842 6.66667 9.87664 6.66667 9.41175ZM14 6.14483V13.946C14 14.2516 13.74 14.5 13.42 14.5H2.58C2.26 14.5 2 14.2516 2 13.946V6.14483C2 5.83915 2.26 5.59079 2.58 5.59079H4V4.43177C4 2.62318 5.26 0.967426 7.11333 0.591698C9.70667 0.0567636 12 1.9354 12 4.3235V5.59716H13.42C13.74 5.59716 14 5.84552 14 6.1512V6.14483ZM5.33333 5.59079H10.6667V4.41903C10.6667 3.19632 9.81333 2.06914 8.56 1.82714C6.84667 1.48962 5.33333 2.73781 5.33333 4.31714V5.59079ZM12.6667 6.86445H3.33333V13.2327H12.6667V6.86445Z"/>
  </g>
</svg>
</template>
